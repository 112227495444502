<template>
  <v-container fluid>
    <v-expansion-panels class="my-1 condensed" hover>
      <v-expansion-panel>
        <v-expansion-panel-header> Notas Principales </v-expansion-panel-header>
        <v-expansion-panel-content
          ><v-btn
            icon
            fab
            color="primary"
            @click="dialogNew = true"
            v-if="tipo != 'ver'"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-data-table
            :headers="headers"
            :items="datosPrincipales.notesFixed.filter((v) => v.state == 1)"
            hide-default-footer
            dense
            disable-sort
            :items-per-page="-1"
          >
            <template v-slot:item="row">
              <tr>
                <td class="indexTblClass">{{ row.index + 1 }}</td>
                <td class="btnDescriptionClass">
                  <v-textarea
                    v-model="row.item.description"
                    hide-details
                    dense
                    rows="1"
                    auto-grow
                    :readonly="tipo == 'ver'"
                  ></v-textarea>
                </td>
                <td class="btnTblClass" v-if="tipo != 'ver'">
                  <v-btn icon color="red" x-small @click="row.item.state = 0">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-expansion-panels class="my-1 condensed" hover>
      <v-expansion-panel>
        <v-expansion-panel-header> Se incluye </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            :headers="headers"
            :items="services.filter((v) => v.status == 1)"
            hide-default-footer
            dense
            :items-per-page="-1"
            disable-sort
          >
            <template v-slot:item="row">
              <tr>
                <td class="indexTblClass">{{ row.index + 1 }}</td>
                <td class="btnDescriptionClass">
                  <v-textarea
                    v-model="row.item.namegroupservice"
                    readonly
                    hide-details
                    dense
                    rows="1"
                    auto-grow
                  ></v-textarea>
                </td>
                <td class="btnTblClass" v-if="tipo != 'ver'">
                  <v-btn icon color="red" x-small @click="row.item.status = 0">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-expansion-panels class="my-1 condensed" hover>
      <v-expansion-panel>
        <v-expansion-panel-header> No Se incluye </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            :headers="headers"
            :items="services.filter((v) => v.status == 0)"
            hide-default-footer
            dense
            :items-per-page="-1"
            disable-sort
          >
            <template v-slot:item="row">
              <tr>
                <td class="indexTblClass">{{ row.index + 1 }}</td>
                <td class="btnDescriptionClass">
                  <v-textarea
                    v-model="row.item.namegroupservice"
                    hide-details
                    dense
                    rows="1"
                    :readonly="tipo == 'ver'"
                    auto-grow
                  ></v-textarea>
                </td>
                <td class="btnTblClass" v-if="tipo != 'ver'">
                  <v-btn icon color="red" x-small @click="row.item.status = 1">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-flex text-right mt-5>
      <v-btn
        color="#2B81D6"
        class="mx-1"
        style="color: white !important"
        small
        v-if="tipo == 'editar'"
        @click="subirCotizacion()"
      >
        GUARDAR PREVIEW
      </v-btn>
      <v-btn
        color="#A43542"
        class="mx-1"
        style="color: white !important"
        small
        @click="previewFlag = true"
      >
        Preview
      </v-btn>
    </v-flex>
    <v-dialog v-model="previewFlag" max-width="500px">
      <v-card color="" class="py-5">
        <v-card-title primary-title> Opciones de Cotización </v-card-title>
        <v-card-text>
          <v-radio-group v-model="tiporeporte" column>
            <v-radio
              label="Costo detallado por cada item"
              color="red darken-3"
              value="DETALLE"
            ></v-radio>

            <v-radio
              label="Totales por servicios"
              color="indigo darken-3"
              value="TOTAL"
            ></v-radio>

            <v-radio
              label="Reporte total general sin desglose"
              color="orange darken-3"
              value="AGRUPADO"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions v-if="generandoFlag">
          <v-progress-linear indeterminate color="red"></v-progress-linear>
          <br />
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="generar"> Aceptar </v-btn>
          <v-btn color="red" text @click="previewFlag = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogNew"
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title> Nueva Nota: </v-card-title>
        <v-card-text>
          <v-form ref="new">
            <v-text-field
              v-model="notaDescripcion"
              label="Descripción"
              :rules="[(v) => !!v || 'Dato Requerido']"
            ></v-text-field>
            <v-btn color="success" @click="nuevaNota()">Aceptar</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" hide-overlay persistent width="400">
      <v-card color="text-h3">
        <v-card-text>
          Subiendo El archivo generado <br />
          <v-progress-circular
            :rotate="180"
            :size="100"
            :width="15"
            :value="value"
            color="red"
          >
            {{ value }}
          </v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import funcion from "@/mixins/funciones";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  props: ["tipo"],
  mixins: [funcion],
  name: "cardNotasComponent",
  data() {
    return {
      dialog: false,
      generandoFlag: false,
      previewFlag: false,
      dialogNew: false,
      tiporeporte: null,
      dialogIntructivo: false,
      notaDescripcion: "",
      value: 0,
      id_branch: JSON.parse(localStorage.getItem("branch")),
      headers: [
        { value: "index", text: "#", align: "center" },
        { value: "description", text: "Descripción", align: "center" },
      ],
      datosEmpresa: null,
    };
  },
  mounted() {
    this.obtenerDatosEmpresa();
    this.setImpuestoFlag();
    if (this.tipo != "ver") {
      this.headers.push({ value: "action", text: "Acciones", align: "center" });
    }
  },
  created() {},
  methods: {
    ...mapActions(["getFilesQuote"]),
    ...mapMutations([
      "updateDatosNotas",
      "setCudFlag",
      "setImpuestoFlag",
      "setActualizarListaArchivos",
      "obtenerIdTab",
      "setRegistrarFlag",
    ]),
    aceptar() {
      this.dialogIntructivo = true;
      this.setCudFlag(true);
      // this.updateDatosNotas();
    },
    cerrarModal() {
      this.dialogIntructivo = false;
    },
    nuevaNota() {
      if (this.$refs.new.validate()) {
        let nota = {
          description: this.notaDescripcion,
          state: 1,
        };
        this.datosPrincipales.notesFixed.push(nota);
        this.dialogNew = false;
        this.$refs.new.reset();
      }
    },
    async obtenerDatosEmpresa() {
      let data = {
        id_branch: JSON.parse(localStorage.getItem("branch")),
      };
      let valores = await this.obtenerDataApi(
        "post",
        process.env.VUE_APP_URL_MAIN +
          `getBranch/${JSON.parse(localStorage.getItem("branch"))}`, //9800
        data
      );
      this.datosEmpresa = valores;
    },
    async generar() {
      if (this.tipo == "nuevo") {
        this.setRegistrarFlag();
      }
      this.generandoFlag = true;
      let totalImpuesto = 0;
      let imp = [];
      let flete = [];
      let locales = [];
      let aduana = [];
      let almacen = [];
      let incluye = [];
      let noincluye = [];
      let importante = [];
      let contenedor = [];
      let totalServiciosPic = 0;
      // GENERALES
      /** AGRUPADO */
      let conceptos = [];
      /** !AGRUPADO */
      let datosFlete = [];
      let datosLocales = [];
      let datosAduanas = [];
      let datosAlmacenes = [];
      let totalFlete = 0;
      let totalLocales = 0;
      let totalAduanas = 0;
      let totalAlmacenes = 0;
      /* contenedor*/
      if (
        this.datosPrincipales.idshipment != 2 &&
        this.datosPrincipales.idshipment != 5
      ) {
        contenedor = [
          {
            name: "Cantidad",
            valor: this.datosPrincipales.bultos + " unidades",
          },
          {
            name: "Volumen",
            valor: this.datosPrincipales.metroscc + " m3.",
          },
          { name: "Peso", valor: this.datosPrincipales.kg + " kg." },
        ];
      } else {
        this.datosPrincipales.containers.forEach((element) => {
          contenedor.push({
            name: element.description,
            valor: element.cantidad,
          });
        });
      }

      /** incluye - no incluye */
      this.services.forEach((element) => {
        if (element.status == 1) {
          incluye.push({
            name: element.nameservice,
          });
        } else {
          noincluye.push({
            name: element.nameservice,
          });
        }
      });

      /* IMPORTANTES */

      this.datosPrincipales.notesFixed
        .filter((v) => v.state == 1)
        .forEach((element) => {
          importante.push({ name: element.description });
        });

      /* FLETE - LOCAL - ADUANA - ALMACEN - SERVICIOS SIEMPRE VENTAFLAG == 0*/
      this.services.forEach((element) => {
        if (element.id_begend == 3 || element.id_begend == 8) {
          flete.push({
            name: element.namegroupservice,
            estado: element.status == 1 || element.status == true ? "SI" : "NO",
          });
        }
        if (element.id_begend == 1 || element.id_begend == 7) {
          locales.push({
            name: element.namegroupservice,
            estado: element.status == 1 || element.status == true ? "SI" : "NO",
          });
        }
        if (element.id_begend == 2) {
          aduana.push({
            name: element.namegroupservice,
            estado: element.status == 1 || element.status == true ? "SI" : "NO",
          });
        }
        if (element.id_begend == 4 || element.id_begend == 9) {
          almacen.push({
            name: element.namegroupservice,
            estado: element.status == 1 || element.status == true ? "SI" : "NO",
          });
        }
      });
      /** DEPENDEN DEL TIPO DE CARGA */
      switch (this.tiporeporte) {
        case "AGRUPADO":
          // CALCULAR TOTALES PARA IGV INICIO
          this.costos
            .filter((v) => v.status == 1 && v.esventaflag == 1)
            .forEach((element) => {
              if (element.esorigenflag == 1) {
                if (
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  ).length > 0
                    ? this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 5 &&
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 13 &&
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 14
                    : 0
                ) {
                  totalFlete +=
                    (this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].valor
                      : 0) *
                    element.costounitario *
                    this.calcularFac(
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      ).length > 0
                        ? this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          )[0].code
                        : "N",
                      this.datosPrincipales.metroscc,
                      this.datosPrincipales.kg,
                      this.datosPrincipales.containers,
                      this.datosPrincipales.amount
                    );
                }
              }
              if (element.eslocalflag == 1) {
                if (
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  )[0].code != 5 &&
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  )[0].code != 13 &&
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  )[0].code != 14
                ) {
                  totalLocales +=
                    (this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].valor
                      : 0) *
                    element.costounitario *
                    this.calcularFac(
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      ).length > 0
                        ? this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          )[0].code
                        : "N",
                      this.datosPrincipales.metroscc,
                      this.datosPrincipales.kg,
                      this.datosPrincipales.containers,
                      this.datosPrincipales.amount
                    );
                } else {
                  totalLocales += this.calcularValor(
                    this.datosPrincipales.amount,
                    this.fleteTotal,
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].code
                      : "",
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? [0].code == 14
                        ? element.cif
                        : element.seguro
                      : 0
                  );
                }
              }
              if (element.esaduanaflag == 1) {
                if (
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  )[0].code != 5 &&
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  )[0].code != 13 &&
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  )[0].code != 14
                ) {
                  totalAduanas +=
                    (this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].valor
                      : 0) *
                    element.costounitario *
                    this.calcularFac(
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      ).length > 0
                        ? this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          )[0].code
                        : "N",
                      this.datosPrincipales.metroscc,
                      this.datosPrincipales.kg,
                      this.datosPrincipales.containers,
                      this.datosPrincipales.amount
                    );
                } else {
                  totalAduanas += this.calcularValor(
                    this.datosPrincipales.amount,
                    this.fleteTotal,
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].code
                      : "",
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? [0].code == 14
                        ? element.cif
                        : element.seguro
                      : 0
                  );
                }
              }

              if (element.esalmacenflag == 1) {
                if (
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  )[0].code != 5 &&
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  )[0].code != 13 &&
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  )[0].code != 14
                ) {
                  totalAlmacenes +=
                    (this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].valor
                      : 0) *
                    element.costounitario *
                    this.calcularFac(
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      ).length > 0
                        ? this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          )[0].code
                        : "N",
                      this.datosPrincipales.metroscc,
                      this.datosPrincipales.kg,
                      this.datosPrincipales.containers,
                      this.datosPrincipales.amount
                    );
                } else {
                  totalAlmacenes += this.calcularValor(
                    this.datosPrincipales.amount,
                    this.fleteTotal,
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].code
                      : "",
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? [0].code == 14
                        ? element.cif
                        : element.seguro
                      : 0
                  );
                }
              }
            });

          // CALCULAR TOTALES PARA IGV
          //
          if (
            this.services.filter((v) => v.codegroupservices == 4).length > 0
              ? this.services.filter((v) => v.codegroupservices == 4)[0].status
              : false
          ) {
            this.costos
              .filter(
                (v) =>
                  v.status == 1 && v.esorigenflag == 1 && v.esventaflag == 1
              )
              .forEach((element) => {
                if (conceptos.filter((v) => v.name == element.nameservice)) {
                  conceptos.push({
                    name: element.nameservice,
                  });
                }
              });
          }
          if (
            (this.services.filter((v) => v.codegroupservices == 3).length > 0
              ? this.services.filter((v) => v.codegroupservices == 3)[0].status
              : false) ||
            (this.services.filter((v) => v.codegroupservices == 5).length > 0
              ? this.services.filter((v) => v.codegroupservices == 5)[0].status
              : false) ||
            (this.services.filter((v) => v.codegroupservices == 9).length > 0
              ? this.services.filter((v) => v.codegroupservices == 9)[0].status
              : false) ||
            (this.services.filter((v) => v.codegroupservices == 10).length > 0
              ? this.services.filter((v) => v.codegroupservices == 10)[0].status
              : false) ||
            (this.services.filter((v) => v.codegroupservices == 11).length > 0
              ? this.services.filter((v) => v.codegroupservices == 11)[0].status
              : false) ||
            (this.services.filter((v) => v.codegroupservices == 12).length > 0
              ? this.services.filter((v) => v.codegroupservices == 12)[0].status
              : false) ||
            (this.services.filter((v) => v.codegroupservices == 13).length > 0
              ? this.services.filter((v) => v.codegroupservices == 13)[0].status
              : false) ||
            (this.services.filter((v) => v.codegroupservices == 16).length > 0
              ? this.services.filter((v) => v.codegroupservices == 16)[0].status
              : false) ||
            (this.services.filter((v) => v.codegroupservices == 17).length > 0
              ? this.services.filter((v) => v.codegroupservices == 17)[0].status
              : false)
          ) {
            this.costos
              .filter(
                (v) => v.status == 1 && v.eslocalflag == 1 && v.esventaflag == 1
              )
              .forEach((element) => {
                if (conceptos.filter((v) => v.name == element.nameservice)) {
                  conceptos.push({
                    name: element.nameservice,
                  });
                }
              });
          }
          if (
            (this.services.filter((v) => v.codegroupservices == 2).length > 0
              ? this.services.filter((v) => v.codegroupservices == 2)[0].status
              : false) ||
            (this.services.filter((v) => v.codegroupservices == 6).length > 0
              ? this.services.filter((v) => v.codegroupservices == 6)[0].status
              : false)
          ) {
            this.costos
              .filter(
                (v) =>
                  v.status == 1 && v.esaduanaflag == 1 && v.esventaflag == 1
              )
              .forEach((element) => {
                if (conceptos.filter((v) => v.name == element.nameservice)) {
                  conceptos.push({
                    name: element.nameservice,
                  });
                }
              });
          }
          if (
            this.services.filter((v) => v.codegroupservices == 7).length > 0
              ? this.services.filter((v) => v.codegroupservices == 7)[0].status
              : false
          ) {
            this.costos
              .filter(
                (v) =>
                  v.status == 1 && v.esalmacenflag == 1 && v.esventaflag == 1
              )
              .forEach((element) => {
                if (conceptos.filter((v) => v.name == element.nameservice)) {
                  conceptos.push({
                    name: element.nameservice,
                  });
                }
              });
          }
          break;

        case "DETALLE":
          this.costos
            .filter((v) => v.status == 1 && v.esventaflag == 1)
            .forEach((element) => {
              if (element.esorigenflag == 1) {
                if (
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  ).length > 0
                    ? this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 5 &&
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 13 &&
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 14
                    : false
                ) {
                  datosFlete.push({
                    name: element.nameservice,
                    valor: this.currencyFormat(
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].valor *
                        element.costounitario *
                        this.calcularFac(
                          this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          ).length > 0
                            ? this.multiplicador.filter(
                                (v) => v.id == element.id_multiplicador
                              )[0].code
                            : "N",
                          this.datosPrincipales.metroscc,
                          this.datosPrincipales.kg,
                          this.datosPrincipales.containers,
                          this.datosPrincipales.amount
                        )
                    ),
                  });
                  totalFlete +=
                    (this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].valor
                      : 0) *
                    element.costounitario *
                    this.calcularFac(
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      ).length > 0
                        ? this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          )[0].code
                        : "N",
                      this.datosPrincipales.metroscc,
                      this.datosPrincipales.kg,
                      this.datosPrincipales.containers,
                      this.datosPrincipales.amount
                    );
                } else {
                  datosFlete.push({
                    name: element.nameservice,
                    valor: this.currencyFormat(
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].valor *
                        element.costounitario *
                        this.calcularFac(
                          this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          ).length > 0
                            ? this.multiplicador.filter(
                                (v) => v.id == element.id_multiplicador
                              )[0].code
                            : "N",
                          this.datosPrincipales.metroscc,
                          this.datosPrincipales.kg,
                          this.datosPrincipales.containers,
                          this.datosPrincipales.amount
                        )
                    ),
                  });
                  totalFlete += this.calcularValor(
                    this.datosPrincipales.amount,
                    this.fleteTotal,
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].code
                      : "",
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? [0].code == 14
                        ? element.cif
                        : element.seguro
                      : 0
                  );
                }
              }

              if (element.eslocalflag == 1) {
                if (
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  ).length > 0
                    ? this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 5 &&
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 13 &&
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 14
                    : false
                ) {
                  datosLocales.push({
                    name: element.nameservice,
                    valor: this.currencyFormat(
                      (this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      ).length > 0
                        ? this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          )[0].valor
                        : 0) *
                        element.costounitario *
                        this.calcularFac(
                          this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          ).length > 0
                            ? this.multiplicador.filter(
                                (v) => v.id == element.id_multiplicador
                              )[0].code
                            : "N",
                          this.datosPrincipales.metroscc,
                          this.datosPrincipales.kg,
                          this.datosPrincipales.containers,
                          this.datosPrincipales.amount
                        )
                    ),
                  });
                  totalLocales +=
                    (this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].valor
                      : 0) *
                    element.costounitario *
                    this.calcularFac(
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      ).length > 0
                        ? this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          )[0].code
                        : "N",
                      this.datosPrincipales.metroscc,
                      this.datosPrincipales.kg,
                      this.datosPrincipales.containers,
                      this.datosPrincipales.amount
                    );
                } else {
                  datosLocales.push({
                    name: element.nameservice,
                    valor: this.currencyFormat(
                      this.calcularValor(
                        this.datosPrincipales.amount,
                        this.fleteTotal,
                        this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        ).length > 0
                          ? this.multiplicador.filter(
                              (v) => v.id == element.id_multiplicador
                            )[0].code
                          : "",
                        this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        ).length > 0
                          ? [0].code == 14
                            ? element.cif
                            : element.seguro
                          : 0
                      )
                    ),
                  });
                  totalLocales += this.calcularValor(
                    this.datosPrincipales.amount,
                    this.fleteTotal,
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].code
                      : "",
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? [0].code == 14
                        ? element.cif
                        : element.seguro
                      : 0
                  );
                }
              }

              if (element.esaduanaflag == 1) {
                if (
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  ).length > 0
                    ? this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 5 &&
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 13 &&
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 14
                    : false
                ) {
                  datosAduanas.push({
                    name: element.nameservice,
                    valor: this.currencyFormat(
                      (this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      ).length > 0
                        ? this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          )[0].valor
                        : 0) *
                        element.costounitario *
                        this.calcularFac(
                          this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          ).length > 0
                            ? this.multiplicador.filter(
                                (v) => v.id == element.id_multiplicador
                              )[0].code
                            : "N",
                          this.datosPrincipales.metroscc,
                          this.datosPrincipales.kg,
                          this.datosPrincipales.containers,
                          this.datosPrincipales.amount
                        )
                    ),
                  });
                  totalAduanas +=
                    (this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].valor
                      : 0) *
                    element.costounitario *
                    this.calcularFac(
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      ).length > 0
                        ? this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          )[0].code
                        : "N",
                      this.datosPrincipales.metroscc,
                      this.datosPrincipales.kg,
                      this.datosPrincipales.containers,
                      this.datosPrincipales.amount
                    );
                } else {
                  datosAduanas.push({
                    name: element.nameservice,
                    valor: this.currencyFormat(
                      this.calcularValor(
                        this.datosPrincipales.amount,
                        this.fleteTotal,
                        this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        ).length > 0
                          ? this.multiplicador.filter(
                              (v) => v.id == element.id_multiplicador
                            )[0].code
                          : "",
                        this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        ).length > 0
                          ? [0].code == 14
                            ? element.cif
                            : element.seguro
                          : 0
                      )
                    ),
                  });
                  totalAduanas += this.calcularValor(
                    this.datosPrincipales.amount,
                    this.fleteTotal,
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].code
                      : "",
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? [0].code == 14
                        ? element.cif
                        : element.seguro
                      : 0
                  );
                }
              }

              if (element.esalmacenflag == 1) {
                if (
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  )[0].code != 5 &&
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  )[0].code != 13 &&
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  )[0].code != 14
                ) {
                  datosAlmacenes.push({
                    name: element.nameservice,
                    valor: this.currencyFormat(
                      (this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      ).length > 0
                        ? this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          )[0].valor
                        : 0) *
                        element.costounitario *
                        this.calcularFac(
                          this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          ).length > 0
                            ? this.multiplicador.filter(
                                (v) => v.id == element.id_multiplicador
                              )[0].code
                            : "N",
                          this.datosPrincipales.metroscc,
                          this.datosPrincipales.kg,
                          this.datosPrincipales.containers,
                          this.datosPrincipales.amount
                        )
                    ),
                  });
                  totalAlmacenes +=
                    (this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].valor
                      : 0) *
                    element.costounitario *
                    this.calcularFac(
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      ).length > 0
                        ? this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          )[0].code
                        : "N",
                      this.datosPrincipales.metroscc,
                      this.datosPrincipales.kg,
                      this.datosPrincipales.containers,
                      this.datosPrincipales.amount
                    );
                } else {
                  datosAlmacenes.push({
                    name: element.nameservice,
                    valor: this.currencyFormat(
                      this.calcularValor(
                        this.datosPrincipales.amount,
                        this.fleteTotal,
                        this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        ).length > 0
                          ? this.multiplicador.filter(
                              (v) => v.id == element.id_multiplicador
                            )[0].code
                          : "",
                        this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        ).length > 0
                          ? [0].code == 14
                            ? element.cif
                            : element.seguro
                          : 0
                      )
                    ),
                  });
                  totalAlmacenes += this.calcularValor(
                    this.datosPrincipales.amount,
                    this.fleteTotal,
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].code
                      : "",
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? [0].code == 14
                        ? element.cif
                        : element.seguro
                      : 0
                  );
                }
              }
            });
          break;

        case "TOTAL":
          this.costos
            .filter((v) => v.status == 1 && v.esventaflag == 1)
            .forEach((element) => {
              if (element.esorigenflag == 1) {
                if (
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  ).length > 0
                    ? this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 5 &&
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 13 &&
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 14
                    : 0
                ) {
                  totalFlete +=
                    (this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].valor
                      : 0) *
                    element.costounitario *
                    this.calcularFac(
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      ).length > 0
                        ? this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          )[0].code
                        : "N",
                      this.datosPrincipales.metroscc,
                      this.datosPrincipales.kg,
                      this.datosPrincipales.containers,
                      this.datosPrincipales.amount
                    );
                }
                datosFlete.push({
                  name: element.nameservice,
                  valor: "",
                });
              }
              if (element.eslocalflag == 1) {
                if (
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  )[0].code != 5 &&
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  )[0].code != 13 &&
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  )[0].code != 14
                ) {
                  totalLocales +=
                    (this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].valor
                      : 0) *
                    element.costounitario *
                    this.calcularFac(
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      ).length > 0
                        ? this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          )[0].code
                        : "N",
                      this.datosPrincipales.metroscc,
                      this.datosPrincipales.kg,
                      this.datosPrincipales.containers,
                      this.datosPrincipales.amount
                    );
                } else {
                  totalLocales += this.calcularValor(
                    this.datosPrincipales.amount,
                    this.fleteTotal,
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].code
                      : "",
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? [0].code == 14
                        ? element.cif
                        : element.seguro
                      : 0
                  );
                }
                datosLocales.push({
                  name: element.nameservice,
                  valor: "",
                });
              }
              if (element.esaduanaflag == 1) {
                if (
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  )[0].code != 5 &&
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  )[0].code != 13 &&
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  )[0].code != 14
                ) {
                  totalAduanas +=
                    (this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].valor
                      : 0) *
                    element.costounitario *
                    this.calcularFac(
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      ).length > 0
                        ? this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          )[0].code
                        : "N",
                      this.datosPrincipales.metroscc,
                      this.datosPrincipales.kg,
                      this.datosPrincipales.containers,
                      this.datosPrincipales.amount
                    );
                } else {
                  totalAduanas += this.calcularValor(
                    this.datosPrincipales.amount,
                    this.fleteTotal,
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].code
                      : "",
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? [0].code == 14
                        ? element.cif
                        : element.seguro
                      : 0
                  );
                }
                datosAduanas.push({
                  name: element.nameservice,
                  valor: "",
                });
              }

              if (element.esalmacenflag == 1) {
                if (
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  )[0].code != 5 &&
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  )[0].code != 13 &&
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  )[0].code != 14
                ) {
                  totalAlmacenes +=
                    (this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].valor
                      : 0) *
                    element.costounitario *
                    this.calcularFac(
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      ).length > 0
                        ? this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          )[0].code
                        : "N",
                      this.datosPrincipales.metroscc,
                      this.datosPrincipales.kg,
                      this.datosPrincipales.containers,
                      this.datosPrincipales.amount
                    );
                } else {
                  totalAlmacenes += this.calcularValor(
                    this.datosPrincipales.amount,
                    this.fleteTotal,
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].code
                      : "",
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? [0].code == 14
                        ? element.cif
                        : element.seguro
                      : 0
                  );
                }
                datosAlmacenes.push({
                  name: element.nameservice,
                  valor: "",
                });
              }
            });
          break;
        default:
          break;
      }

      // totalServiciosPic
      this.costos
        .filter((v) => v.status == 1 && v.esventaflag == 1)
        .forEach((element) => {
          if (
            this.multiplicador.filter((v) => v.id == element.id_multiplicador)
              .length > 0
          ) {
            if (
              this.multiplicador.filter(
                (v) => v.id == element.id_multiplicador
              )[0].code != 5 &&
              this.multiplicador.filter(
                (v) => v.id == element.id_multiplicador
              )[0].code != 13 &&
              this.multiplicador.filter(
                (v) => v.id == element.id_multiplicador
              )[0].code != 14
            ) {
              totalServiciosPic +=
                (this.multiplicador.filter(
                  (v) => v.id == element.id_multiplicador
                ).length > 0
                  ? this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    )[0].valor
                  : 0) *
                element.costounitario *
                this.calcularFac(
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  ).length > 0
                    ? this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code
                    : "N",
                  this.datosPrincipales.metroscc,
                  this.datosPrincipales.kg,
                  this.datosPrincipales.containers,
                  this.datosPrincipales.amount
                );
            } else {
              totalServiciosPic += this.calcularValor(
                this.datosPrincipales.amount,
                this.fleteTotal,
                this.multiplicador.filter(
                  (v) => v.id == element.id_multiplicador
                ).length > 0
                  ? this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    )[0].code
                  : "",
                this.multiplicador.filter(
                  (v) => v.id == element.id_multiplicador
                ).length > 0
                  ? [0].code == 14
                    ? element.cif
                    : element.seguro
                  : 0
              );
            }
          }
        });
      if (
        this.services.filter((v) => v.codegroupservices == 15).length > 0
          ? this.services.filter((v) => v.codegroupservices == 15)[0].status
          : false
      ) {
        this.datosPrincipales.impuestos
          .filter((v) => v.type > 4)
          .forEach((element) => {
            imp.push({
              type: element.type,
              name: element.name,
              percentage: element.percentage,
              valor: this.currencyFormat(element.valor),
            });
            totalImpuesto += element.valor;
          });
      }
      /** AXIOS */

      let url = "";

      if (this.tiporeporte === "AGRUPADO") {
        url = process.env.VUE_APP_URL_REPORT + "quote_preview_agrupado";
      } else {
        url = process.env.VUE_APP_URL_REPORT + "quote_preview_totales";
      }
      // let url = "https://api-reportes.piccargo.com/api/quote_preview";
      if (this.tipo == "nuevo") {
        setTimeout(() => {
          let data = {
            id_branch: JSON.parse(localStorage.getItem("branch")),
            business_name: this.datosEmpresa[0].business_name
              ? this.datosEmpresa[0].business_name
              : "",
            address: this.datosEmpresa[0].address,
            tipo: this.tiporeporte,
            cliente: this.datosPrincipales.name,
            slogancliente: this.datosPrincipales.slogan,
            code: this.datosPrincipales.quote,
            fechafin: this.datosPrincipales.fecha_fin,
            tiempoTransito: this.datosPrincipales.tiempo_transito,
            origen:
              this.portBegin.filter(
                (v) => v.value == this.datosPrincipales.idPortBegin
              ).length > 0
                ? this.portBegin.filter(
                    (v) => v.value == this.datosPrincipales.idPortBegin
                  )[0].text
                : "",
            destino:
              this.portEnd.filter(
                (v) => v.value == this.datosPrincipales.idPortEnd
              ).length > 0
                ? this.portEnd.filter(
                    (v) => v.value == this.datosPrincipales.idPortEnd
                  )[0].text
                : "",
            impuesto:
              this.services.filter((v) => v.codegroupservices == 15).length > 0
                ? this.services.filter((v) => v.codegroupservices == 15)[0]
                    .status == true ||
                  this.services.filter((v) => v.codegroupservices == 15)[0]
                    .status == 1
                  ? imp
                  : 0
                : 0,

            flete: flete,
            almacen: almacen,
            aduana: aduana,
            local: locales,
            totalImpuesto: this.currencyFormat((totalImpuesto * 100) / 100),
            incluye: incluye,
            noincluye: noincluye,
            importante: importante,
            contenedor: contenedor,
            conceptos: conceptos,
            sentido: this.modality.filter(
              (v) => v.value == this.datosPrincipales.idmodality
            )[0].text,
            embarque:
              this.shipment.filter(
                (v) => v.value == this.datosPrincipales.idshipment.value
              ).length > 0
                ? this.shipment.filter(
                    (v) => v.value == this.datosPrincipales.idshipment.value
                  )[0].text
                : this.shipment.filter(
                    (v) => v.value == this.datosPrincipales.idshipment
                  )[0].text,
            icoterm: this.incoterm.filter(
              (v) => v.value == this.datosPrincipales.idincoterm
            )[0].text,
            //
            datosFlete: datosFlete,
            datosLocales: datosLocales,
            datosAduanas: datosAduanas,
            datosAlmacenes: datosAlmacenes,
            totalImpuestosIGV:
              this.id_branch == 1
                ? this.currencyFormat(
                    (parseFloat(totalLocales) +
                      parseFloat(totalAduanas) +
                      parseFloat(totalAlmacenes)) *
                      0.18
                  )
                : this.currencyFormat(0),
            //
            totalFlete: this.currencyFormat(totalFlete),
            totalLocales: this.currencyFormat(totalLocales),
            totalAduanas: this.currencyFormat(totalAduanas),
            totalAlmacenes: this.currencyFormat(totalAlmacenes),
            totalServicios: this.currencyFormat(totalServiciosPic),
            total: this.currencyFormat(
              totalServiciosPic +
                +parseFloat(
                  this.services.filter((v) => v.codegroupservices == 15)
                    .length > 0
                    ? this.services.filter((v) => v.codegroupservices == 15)[0]
                        .status == true ||
                      this.services.filter((v) => v.codegroupservices == 15)[0]
                        .status == 1
                      ? totalImpuesto
                      : 0
                    : 0
                ) +
                parseFloat(
                  this.id_branch == 1
                    ? (parseFloat(totalLocales) +
                        parseFloat(totalAduanas) +
                        parseFloat(totalAlmacenes)) *
                        0.18
                    : 0
                )
            ),
          };
          let headers = {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "aplication/json",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length",
            responseType: "arraybuffer",
          };
          axios
            .post(url, data, headers)
            .then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              let name = this.uuidv4();
              link.setAttribute("download", name + ".pdf");
              document.body.appendChild(link);
              link.click();
              this.previewFlag = false;
              this.generandoFlag = false;
            })
            .catch((e) => this.cerrarModalPrev(e));
        }, 5000);
      } else {
        let data = {
          id_branch: JSON.parse(localStorage.getItem("branch")),
          business_name: this.datosEmpresa[0].business_name
            ? this.datosEmpresa[0].business_name
            : "",
          address: this.datosEmpresa[0].address,
          tipo: this.tiporeporte,
          cliente: this.datosPrincipales.name,
          slogancliente: this.datosPrincipales.slogan,
          code: this.datosPrincipales.quote,
          fechafin: this.datosPrincipales.fecha_fin,
          tiempoTransito: this.datosPrincipales.tiempo_transito,
          origen:
            this.portBegin.filter(
              (v) => v.value == this.datosPrincipales.idPortBegin
            ).length > 0
              ? this.portBegin.filter(
                  (v) => v.value == this.datosPrincipales.idPortBegin
                )[0].text
              : "",
          destino:
            this.portEnd.filter(
              (v) => v.value == this.datosPrincipales.idPortEnd
            ).length > 0
              ? this.portEnd.filter(
                  (v) => v.value == this.datosPrincipales.idPortEnd
                )[0].text
              : "",
          impuesto:
            this.services.filter((v) => v.codegroupservices == 15).length > 0
              ? this.services.filter((v) => v.codegroupservices == 15)[0]
                  .status == true ||
                this.services.filter((v) => v.codegroupservices == 15)[0]
                  .status == 1
                ? imp
                : 0
              : 0,

          flete: flete,
          almacen: almacen,
          aduana: aduana,
          local: locales,
          totalImpuesto: this.currencyFormat((totalImpuesto * 100) / 100),
          incluye: incluye,
          noincluye: noincluye,
          importante: importante,
          contenedor: contenedor,
          conceptos: conceptos,
          sentido: this.modality.filter(
            (v) => v.value == this.datosPrincipales.idmodality
          )[0].text,
          embarque:
            this.shipment.filter(
              (v) => v.value == this.datosPrincipales.idshipment.value
            ).length > 0
              ? this.shipment.filter(
                  (v) => v.value == this.datosPrincipales.idshipment.value
                )[0].text
              : this.shipment.filter(
                  (v) => v.value == this.datosPrincipales.idshipment
                )[0].text,
          icoterm: this.incoterm.filter(
            (v) => v.value == this.datosPrincipales.idincoterm
          )[0].text,
          //
          datosFlete: datosFlete,
          datosLocales: datosLocales,
          datosAduanas: datosAduanas,
          datosAlmacenes: datosAlmacenes,
          totalImpuestosIGV:
            this.id_branch == 1
              ? this.currencyFormat(
                  (parseFloat(totalLocales) +
                    parseFloat(totalAduanas) +
                    parseFloat(totalAlmacenes)) *
                    0.18
                )
              : this.currencyFormat(0),
          //
          totalFlete: this.currencyFormat(totalFlete),
          totalLocales: this.currencyFormat(totalLocales),
          totalAduanas: this.currencyFormat(totalAduanas),
          totalAlmacenes: this.currencyFormat(totalAlmacenes),
          totalServicios: this.currencyFormat(totalServiciosPic),
          total: this.currencyFormat(
            totalServiciosPic +
              +parseFloat(
                this.services.filter((v) => v.codegroupservices == 15).length >
                  0
                  ? this.services.filter((v) => v.codegroupservices == 15)[0]
                      .status == true ||
                    this.services.filter((v) => v.codegroupservices == 15)[0]
                      .status == 1
                    ? totalImpuesto
                    : 0
                  : 0
              ) +
              parseFloat(
                this.id_branch == 1
                  ? (parseFloat(totalLocales) +
                      parseFloat(totalAduanas) +
                      parseFloat(totalAlmacenes)) *
                      0.18
                  : 0
              )
          ),
        };

        let headers = {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "aplication/json",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers":
            "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length",
          responseType: "arraybuffer",
        };
        axios
          .post(url, data, headers)
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            let name = this.uuidv4();
            link.setAttribute("download", name + ".pdf");
            document.body.appendChild(link);
            link.click();
            this.previewFlag = false;
            this.generandoFlag = false;
          })
          .catch((e) => this.cerrarModalPrev(e));
      }
    },

    async subirCotizacion() {
      this.dialog = true;
      this.aumentarCarga(1);
      let totalImpuesto = 0;
      let imp = [];
      let flete = [];
      let locales = [];
      let aduana = [];
      let almacen = [];
      let incluye = [];
      let noincluye = [];
      let importante = [];
      let contenedor = [];
      let totalServiciosPic = 0;
      // GENERALES
      /** AGRUPADO */
      let conceptos = [];
      /** !AGRUPADO */
      let datosFlete = [];
      let datosLocales = [];
      let datosAduanas = [];
      let datosAlmacenes = [];
      let totalFlete = 0;
      let totalLocales = 0;
      let totalAduanas = 0;
      let totalAlmacenes = 0;
      /* contenedor*/
      if (
        this.datosPrincipales.idshipment != 2 &&
        this.datosPrincipales.idshipment != 5
      ) {
        contenedor = [
          {
            name: "Cantidad",
            valor: this.datosPrincipales.bultos + " unidades",
          },
          {
            name: "Volumen",
            valor: this.datosPrincipales.metroscc + " m3.",
          },
          { name: "Peso", valor: this.datosPrincipales.kg + " kg." },
        ];
      } else {
        this.datosPrincipales.containers.forEach((element) => {
          contenedor.push({
            name: element.description,
            valor: element.cantidad,
          });
        });
      }

      /** incluye - no incluye */
      this.services.forEach((element) => {
        if (element.status == 1) {
          incluye.push({
            name: element.nameservice,
          });
        } else {
          noincluye.push({
            name: element.nameservice,
          });
        }
      });

      /* IMPORTANTES */

      this.datosPrincipales.notesFixed
        .filter((v) => v.state == 1)
        .forEach((element) => {
          importante.push({ name: element.description });
        });
      /* FLETE - LOCAL - ADUANA - ALMACEN - SERVICIOS SIEMPRE VENTAFLAG == 0*/
      this.services.forEach((element) => {
        if (element.id_begend == 3) {
          flete.push({
            name: element.namegroupservice,
            estado: element.status == 1 || element.status == true ? "SI" : "NO",
          });
        }
        if (element.id_begend == 1) {
          locales.push({
            name: element.namegroupservice,
            estado: element.status == 1 || element.status == true ? "SI" : "NO",
          });
        }
        if (element.id_begend == 2) {
          aduana.push({
            name: element.namegroupservice,
            estado: element.status == 1 || element.status == true ? "SI" : "NO",
          });
        }
        if (element.id_begend == 4) {
          almacen.push({
            name: element.namegroupservice,
            estado: element.status == 1 || element.status == true ? "SI" : "NO",
          });
        }
      });
      /** DEPENDEN DEL TIPO DE CARGA */
      this.tiporeporte = this.tiporeporte ? this.tiporeporte : "TOTAL";
      switch (this.tiporeporte) {
        case "AGRUPADO":
          // CALCULAR TOTALES PARA IGV INICIO
          this.costos
            .filter((v) => v.status == 1 && v.esventaflag == 1)
            .forEach((element) => {
              if (element.esorigenflag == 1) {
                if (
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  ).length > 0
                    ? this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 5 &&
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 13 &&
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 14
                    : 0
                ) {
                  totalFlete +=
                    (this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].valor
                      : 0) *
                    element.costounitario *
                    this.calcularFac(
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      ).length > 0
                        ? this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          )[0].code
                        : "N",
                      this.datosPrincipales.metroscc,
                      this.datosPrincipales.kg,
                      this.datosPrincipales.containers,
                      this.datosPrincipales.amount
                    );
                }
              }
              if (element.eslocalflag == 1) {
                if (
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  )[0].code != 5 &&
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  )[0].code != 13 &&
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  )[0].code != 14
                ) {
                  totalLocales +=
                    (this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].valor
                      : 0) *
                    element.costounitario *
                    this.calcularFac(
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      ).length > 0
                        ? this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          )[0].code
                        : "N",
                      this.datosPrincipales.metroscc,
                      this.datosPrincipales.kg,
                      this.datosPrincipales.containers,
                      this.datosPrincipales.amount
                    );
                } else {
                  totalLocales += this.calcularValor(
                    this.datosPrincipales.amount,
                    this.fleteTotal,
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].code
                      : "",
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? [0].code == 14
                        ? element.cif
                        : element.seguro
                      : 0
                  );
                }
              }
              if (element.esaduanaflag == 1) {
                if (
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  )[0].code != 5 &&
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  )[0].code != 13 &&
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  )[0].code != 14
                ) {
                  totalAduanas +=
                    (this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].valor
                      : 0) *
                    element.costounitario *
                    this.calcularFac(
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      ).length > 0
                        ? this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          )[0].code
                        : "N",
                      this.datosPrincipales.metroscc,
                      this.datosPrincipales.kg,
                      this.datosPrincipales.containers,
                      this.datosPrincipales.amount
                    );
                } else {
                  totalAduanas += this.calcularValor(
                    this.datosPrincipales.amount,
                    this.fleteTotal,
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].code
                      : "",
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? [0].code == 14
                        ? element.cif
                        : element.seguro
                      : 0
                  );
                }
              }

              if (element.esalmacenflag == 1) {
                if (
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  )[0].code != 5 &&
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  )[0].code != 13 &&
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  )[0].code != 14
                ) {
                  totalAlmacenes +=
                    (this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].valor
                      : 0) *
                    element.costounitario *
                    this.calcularFac(
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      ).length > 0
                        ? this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          )[0].code
                        : "N",
                      this.datosPrincipales.metroscc,
                      this.datosPrincipales.kg,
                      this.datosPrincipales.containers,
                      this.datosPrincipales.amount
                    );
                } else {
                  totalAlmacenes += this.calcularValor(
                    this.datosPrincipales.amount,
                    this.fleteTotal,
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].code
                      : "",
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? [0].code == 14
                        ? element.cif
                        : element.seguro
                      : 0
                  );
                }
              }
            });

          // CALCULAR TOTALES PARA IGV
          //
          this.costos
            .filter(
              (v) => v.status == 1 && v.esorigenflag == 1 && v.esventaflag == 1
            )
            .forEach((element) => {
              if (conceptos.filter((v) => v.name == element.nameservice)) {
                conceptos.push({
                  name: element.nameservice,
                });
              }
            });
          this.costos
            .filter(
              (v) => v.status == 1 && v.eslocalflag == 1 && v.esventaflag == 1
            )
            .forEach((element) => {
              if (conceptos.filter((v) => v.name == element.nameservice)) {
                conceptos.push({
                  name: element.nameservice,
                });
              }
            });
          this.costos
            .filter(
              (v) => v.status == 1 && v.esaduanaflag == 1 && v.esventaflag == 1
            )
            .forEach((element) => {
              if (conceptos.filter((v) => v.name == element.nameservice)) {
                conceptos.push({
                  name: element.nameservice,
                });
              }
            });
          this.costos
            .filter(
              (v) => v.status == 1 && v.esalmacenflag == 1 && v.esventaflag == 1
            )
            .forEach((element) => {
              if (conceptos.filter((v) => v.name == element.nameservice)) {
                conceptos.push({
                  name: element.nameservice,
                });
              }
            });
          break;

        case "DETALLE":
          this.costos
            .filter((v) => v.status == 1 && v.esventaflag == 1)
            .forEach((element) => {
              if (element.esorigenflag == 1) {
                if (
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  ).length > 0
                    ? this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 5 &&
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 13 &&
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 14
                    : false
                ) {
                  datosFlete.push({
                    name: element.nameservice,
                    valor: this.currencyFormat(
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].valor *
                        element.costounitario *
                        this.calcularFac(
                          this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          ).length > 0
                            ? this.multiplicador.filter(
                                (v) => v.id == element.id_multiplicador
                              )[0].code
                            : "N",
                          this.datosPrincipales.metroscc,
                          this.datosPrincipales.kg,
                          this.datosPrincipales.containers,
                          this.datosPrincipales.amount
                        )
                    ),
                  });
                  totalFlete +=
                    (this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].valor
                      : 0) *
                    element.costounitario *
                    this.calcularFac(
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      ).length > 0
                        ? this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          )[0].code
                        : "N",
                      this.datosPrincipales.metroscc,
                      this.datosPrincipales.kg,
                      this.datosPrincipales.containers,
                      this.datosPrincipales.amount
                    );
                } else {
                  datosFlete.push({
                    name: element.nameservice,
                    valor: this.currencyFormat(
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].valor *
                        element.costounitario *
                        this.calcularFac(
                          this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          ).length > 0
                            ? this.multiplicador.filter(
                                (v) => v.id == element.id_multiplicador
                              )[0].code
                            : "N",
                          this.datosPrincipales.metroscc,
                          this.datosPrincipales.kg,
                          this.datosPrincipales.containers,
                          this.datosPrincipales.amount
                        )
                    ),
                  });
                  totalFlete += this.calcularValor(
                    this.datosPrincipales.amount,
                    this.fleteTotal,
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].code
                      : "",
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? [0].code == 14
                        ? element.cif
                        : element.seguro
                      : 0
                  );
                }
              }

              if (element.eslocalflag == 1) {
                if (
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  ).length > 0
                    ? this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 5 &&
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 13 &&
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 14
                    : false
                ) {
                  datosLocales.push({
                    name: element.nameservice,
                    valor: this.currencyFormat(
                      (this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      ).length > 0
                        ? this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          )[0].valor
                        : 0) *
                        element.costounitario *
                        this.calcularFac(
                          this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          ).length > 0
                            ? this.multiplicador.filter(
                                (v) => v.id == element.id_multiplicador
                              )[0].code
                            : "N",
                          this.datosPrincipales.metroscc,
                          this.datosPrincipales.kg,
                          this.datosPrincipales.containers,
                          this.datosPrincipales.amount
                        )
                    ),
                  });
                  totalLocales +=
                    (this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].valor
                      : 0) *
                    element.costounitario *
                    this.calcularFac(
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      ).length > 0
                        ? this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          )[0].code
                        : "N",
                      this.datosPrincipales.metroscc,
                      this.datosPrincipales.kg,
                      this.datosPrincipales.containers,
                      this.datosPrincipales.amount
                    );
                } else {
                  datosLocales.push({
                    name: element.nameservice,
                    valor: this.currencyFormat(
                      this.calcularValor(
                        this.datosPrincipales.amount,
                        this.fleteTotal,
                        this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        ).length > 0
                          ? this.multiplicador.filter(
                              (v) => v.id == element.id_multiplicador
                            )[0].code
                          : "",
                        this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        ).length > 0
                          ? [0].code == 14
                            ? element.cif
                            : element.seguro
                          : 0
                      )
                    ),
                  });
                  totalLocales += this.calcularValor(
                    this.datosPrincipales.amount,
                    this.fleteTotal,
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].code
                      : "",
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? [0].code == 14
                        ? element.cif
                        : element.seguro
                      : 0
                  );
                }
              }

              if (element.esaduanaflag == 1) {
                if (
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  ).length > 0
                    ? this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 5 &&
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 13 &&
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 14
                    : false
                ) {
                  datosAduanas.push({
                    name: element.nameservice,
                    valor: this.currencyFormat(
                      (this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      ).length > 0
                        ? this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          )[0].valor
                        : 0) *
                        element.costounitario *
                        this.calcularFac(
                          this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          ).length > 0
                            ? this.multiplicador.filter(
                                (v) => v.id == element.id_multiplicador
                              )[0].code
                            : "N",
                          this.datosPrincipales.metroscc,
                          this.datosPrincipales.kg,
                          this.datosPrincipales.containers,
                          this.datosPrincipales.amount
                        )
                    ),
                  });
                  totalAduanas +=
                    (this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].valor
                      : 0) *
                    element.costounitario *
                    this.calcularFac(
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      ).length > 0
                        ? this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          )[0].code
                        : "N",
                      this.datosPrincipales.metroscc,
                      this.datosPrincipales.kg,
                      this.datosPrincipales.containers,
                      this.datosPrincipales.amount
                    );
                } else {
                  datosAduanas.push({
                    name: element.nameservice,
                    valor: this.currencyFormat(
                      this.calcularValor(
                        this.datosPrincipales.amount,
                        this.fleteTotal,
                        this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        ).length > 0
                          ? this.multiplicador.filter(
                              (v) => v.id == element.id_multiplicador
                            )[0].code
                          : "",
                        this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        ).length > 0
                          ? [0].code == 14
                            ? element.cif
                            : element.seguro
                          : 0
                      )
                    ),
                  });
                  totalAduanas += this.calcularValor(
                    this.datosPrincipales.amount,
                    this.fleteTotal,
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].code
                      : "",
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? [0].code == 14
                        ? element.cif
                        : element.seguro
                      : 0
                  );
                }
              }

              if (element.esalmacenflag == 1) {
                if (
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  )[0].code != 5 &&
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  )[0].code != 13 &&
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  )[0].code != 14
                ) {
                  datosAlmacenes.push({
                    name: element.nameservice,
                    valor: this.currencyFormat(
                      (this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      ).length > 0
                        ? this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          )[0].valor
                        : 0) *
                        element.costounitario *
                        this.calcularFac(
                          this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          ).length > 0
                            ? this.multiplicador.filter(
                                (v) => v.id == element.id_multiplicador
                              )[0].code
                            : "N",
                          this.datosPrincipales.metroscc,
                          this.datosPrincipales.kg,
                          this.datosPrincipales.containers,
                          this.datosPrincipales.amount
                        )
                    ),
                  });
                  totalAlmacenes +=
                    (this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].valor
                      : 0) *
                    element.costounitario *
                    this.calcularFac(
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      ).length > 0
                        ? this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          )[0].code
                        : "N",
                      this.datosPrincipales.metroscc,
                      this.datosPrincipales.kg,
                      this.datosPrincipales.containers,
                      this.datosPrincipales.amount
                    );
                } else {
                  datosAlmacenes.push({
                    name: element.nameservice,
                    valor: this.currencyFormat(
                      this.calcularValor(
                        this.datosPrincipales.amount,
                        this.fleteTotal,
                        this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        ).length > 0
                          ? this.multiplicador.filter(
                              (v) => v.id == element.id_multiplicador
                            )[0].code
                          : "",
                        this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        ).length > 0
                          ? [0].code == 14
                            ? element.cif
                            : element.seguro
                          : 0
                      )
                    ),
                  });
                  totalAlmacenes += this.calcularValor(
                    this.datosPrincipales.amount,
                    this.fleteTotal,
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].code
                      : "",
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? [0].code == 14
                        ? element.cif
                        : element.seguro
                      : 0
                  );
                }
              }
            });
          break;

        case "TOTAL":
          this.costos
            .filter((v) => v.status == 1 && v.esventaflag == 1)
            .forEach((element) => {
              if (element.esorigenflag == 1) {
                if (
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  ).length > 0
                    ? this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 5 &&
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 13 &&
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 14
                    : 0
                ) {
                  totalFlete +=
                    (this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].valor
                      : 0) *
                    element.costounitario *
                    this.calcularFac(
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      ).length > 0
                        ? this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          )[0].code
                        : "N",
                      this.datosPrincipales.metroscc,
                      this.datosPrincipales.kg,
                      this.datosPrincipales.containers,
                      this.datosPrincipales.amount
                    );
                }
              }
              if (element.eslocalflag == 1) {
                if (
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  ).length > 0
                    ? this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code == 13
                    : false
                ) {
                  totalLocales +=
                    (element.seguro / 100) *
                      (parseFloat(this.totalDeFlete) +
                        parseFloat(this.datosPrincipales.amount)) +
                      0.015 *
                        (element.seguro / 100) *
                        (parseFloat(this.totalDeFlete) +
                          parseFloat(this.datosPrincipales.amount)) >=
                    118
                      ? (element.seguro / 100) *
                          (parseFloat(this.totalDeFlete) +
                            parseFloat(this.datosPrincipales.amount)) +
                        0.015 *
                          (element.seguro / 100) *
                          (parseFloat(this.totalDeFlete) +
                            parseFloat(this.datosPrincipales.amount))
                      : 118;
                }
                if (
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  ).length > 0
                    ? this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 5 &&
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 13 &&
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 14
                    : 0
                ) {
                  totalLocales +=
                    (this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].valor
                      : 0) *
                    element.costounitario *
                    this.calcularFac(
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      ).length > 0
                        ? this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          )[0].code
                        : "N",
                      this.datosPrincipales.metroscc,
                      this.datosPrincipales.kg,
                      this.datosPrincipales.containers,
                      this.datosPrincipales.amount
                    );
                }
                if (
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  ).length > 0
                    ? this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code == 14
                    : 0
                ) {
                  totalLocales +=
                    (element.cif / 100) *
                      (parseFloat(this.fleteTotal) +
                        parseFloat(this.datosPrincipales.amount) +
                        0.015 *
                          (parseFloat(this.fleteTotal) +
                            parseFloat(this.datosPrincipales.amount))) >=
                    118
                      ? (element.cif / 100) *
                        (parseFloat(this.fleteTotal) +
                          parseFloat(this.datosPrincipales.amount) +
                          0.015 *
                            (parseFloat(this.fleteTotal) +
                              parseFloat(this.datosPrincipales.amount)))
                      : 118;
                }
                if (
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  ).length > 0
                    ? this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code == 5
                    : 0
                ) {
                  totalLocales +=
                    (element.seguro / 100) *
                      (parseFloat(this.fleteTotal) +
                        parseFloat(this.datosPrincipales.amount)) >=
                    60
                      ? (element.seguro / 100) *
                        (parseFloat(this.fleteTotal) +
                          parseFloat(this.datosPrincipales.amount))
                      : 60;
                }
              }
              if (element.esaduanaflag == 1) {
                if (
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  ).length > 0
                    ? this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 5 &&
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 13 &&
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 14
                    : 0
                ) {
                  totalAduanas +=
                    (this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].valor
                      : 0) *
                    element.costounitario *
                    this.calcularFac(
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      ).length > 0
                        ? this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          )[0].code
                        : "N",
                      this.datosPrincipales.metroscc,
                      this.datosPrincipales.kg,
                      this.datosPrincipales.containers,
                      this.datosPrincipales.amount
                    );
                }
                if (
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  ).length > 0
                    ? this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code == 14
                    : 0
                ) {
                  totalAduanas +=
                    (element.cif / 100) *
                      (parseFloat(this.totalDeFlete) +
                        parseFloat(this.datosPrincipales.amount) +
                        0.015 *
                          (parseFloat(this.totalDeFlete) +
                            parseFloat(this.datosPrincipales.amount))) >=
                    118
                      ? (element.cif / 100) *
                        (parseFloat(this.totalDeFlete) +
                          parseFloat(this.datosPrincipales.amount) +
                          0.015 *
                            (parseFloat(this.totalDeFlete) +
                              parseFloat(this.datosPrincipales.amount)))
                      : 118;
                }
                if (
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  ).length > 0
                    ? this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code == 5
                    : 0
                ) {
                  totalAduanas +=
                    (element.seguro / 100) *
                      (parseFloat(this.totalDeFlete) +
                        parseFloat(this.datosPrincipales.amount)) >=
                    60
                      ? (element.seguro / 100) *
                        (parseFloat(this.totalDeFlete) +
                          parseFloat(this.datosPrincipales.amount))
                      : 60;
                }
                if (
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  ).length > 0
                    ? this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code == 13
                    : 0
                ) {
                  totalAduanas +=
                    (element.seguro / 100) *
                      (parseFloat(this.totalDeFlete) +
                        parseFloat(this.datosPrincipales.amount)) +
                      0.015 *
                        (element.seguro / 100) *
                        (parseFloat(this.totalDeFlete) +
                          parseFloat(this.datosPrincipales.amount)) >=
                    118
                      ? (element.seguro / 100) *
                          (parseFloat(this.totalDeFlete) +
                            parseFloat(this.datosPrincipales.amount)) +
                        0.015 *
                          (element.seguro / 100) *
                          (parseFloat(this.totalDeFlete) +
                            parseFloat(this.datosPrincipales.amount))
                      : 118;
                }
              }

              if (element.esalmacenflag == 1) {
                if (
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  ).length > 0
                    ? this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code == 13
                    : 0
                ) {
                  totalAlmacenes +=
                    (element.seguro / 100) *
                      (parseFloat(this.totalDeFlete) +
                        parseFloat(this.datosPrincipales.amount)) +
                      0.015 *
                        (element.seguro / 100) *
                        (parseFloat(this.totalDeFlete) +
                          parseFloat(this.datosPrincipales.amount)) >=
                    118
                      ? (element.seguro / 100) *
                          (parseFloat(this.totalDeFlete) +
                            parseFloat(this.datosPrincipales.amount)) +
                        0.015 *
                          (element.seguro / 100) *
                          (parseFloat(this.totalDeFlete) +
                            parseFloat(this.datosPrincipales.amount))
                      : 118;
                }
                if (
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  ).length > 0
                    ? this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 5 &&
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 13 &&
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code != 14
                    : 0
                ) {
                  totalAlmacenes +=
                    (this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].valor
                      : 0) *
                    element.costounitario *
                    this.calcularFac(
                      this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      ).length > 0
                        ? this.multiplicador.filter(
                            (v) => v.id == element.id_multiplicador
                          )[0].code
                        : "N",
                      this.datosPrincipales.metroscc,
                      this.datosPrincipales.kg,
                      this.datosPrincipales.containers,
                      this.datosPrincipales.amount
                    );
                }
                if (
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  ).length > 0
                    ? this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code == 14
                    : 0
                ) {
                  totalAlmacenes +=
                    (element.cif / 100) *
                      (parseFloat(this.totalDeFlete) +
                        parseFloat(this.datosPrincipales.amount) +
                        0.015 *
                          (parseFloat(this.totalDeFlete) +
                            parseFloat(this.datosPrincipales.amount))) >=
                    118
                      ? (element.cif / 100) *
                        (parseFloat(this.totalDeFlete) +
                          parseFloat(this.datosPrincipales.amount) +
                          0.015 *
                            (parseFloat(this.totalDeFlete) +
                              parseFloat(this.datosPrincipales.amount)))
                      : 118;
                }
                if (
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  ).length > 0
                    ? this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code == 5
                    : 0
                ) {
                  totalAlmacenes +=
                    (element.seguro / 100) *
                      (parseFloat(this.totalDeFlete) +
                        parseFloat(this.datosPrincipales.amount)) >=
                    60
                      ? (element.seguro / 100) *
                        (parseFloat(this.totalDeFlete) +
                          parseFloat(this.datosPrincipales.amount))
                      : 60;
                }
              }
            });
          break;
        default:
          break;
      }

      // totalServiciosPic
      this.costos
        .filter((v) => v.status == 1 && v.esventaflag == 1)
        .forEach((element) => {
          if (
            this.multiplicador.filter(
              (v) => v.id == element.id_multiplicador
            )[0].code != 5 &&
            this.multiplicador.filter(
              (v) => v.id == element.id_multiplicador
            )[0].code != 13 &&
            this.multiplicador.filter(
              (v) => v.id == element.id_multiplicador
            )[0].code != 14
          ) {
            totalServiciosPic +=
              (this.multiplicador.filter(
                (v) => v.id == element.id_multiplicador
              ).length > 0
                ? this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  )[0].valor
                : 0) *
              element.costounitario *
              this.calcularFac(
                this.multiplicador.filter(
                  (v) => v.id == element.id_multiplicador
                ).length > 0
                  ? this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    )[0].code
                  : "N",
                this.datosPrincipales.metroscc,
                this.datosPrincipales.kg,
                this.datosPrincipales.containers,
                this.datosPrincipales.amount
              );
          } else {
            totalServiciosPic += this.calcularValor(
              this.datosPrincipales.amount,
              this.fleteTotal,
              this.multiplicador.filter((v) => v.id == element.id_multiplicador)
                .length > 0
                ? this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  )[0].code
                : "",
              this.multiplicador.filter((v) => v.id == element.id_multiplicador)
                .length > 0
                ? [0].code == 14
                  ? element.cif
                  : element.seguro
                : 0
            );
          }
        });
      if (
        this.services.filter((v) => v.codegroupservices == 15).length > 0
          ? this.services.filter((v) => v.codegroupservices == 15)[0].status
          : false
      ) {
        this.datosPrincipales.impuestos
          .filter((v) => v.type > 4)
          .forEach((element) => {
            imp.push({
              type: element.type,
              name: element.name,
              percentage: element.percentage,
              valor: this.currencyFormat(element.valor),
            });
            totalImpuesto += element.valor;
          });
      }
      /** AXIOS */
      let data = {
        id_branch: JSON.parse(localStorage.getItem("branch")),
        code: this.datosPrincipales.quote,
        business_name: this.datosEmpresa[0].business_name
          ? this.datosEmpresa[0].business_name
          : "",
        address: this.datosEmpresa[0].address,
        tipo: this.tiporeporte,
        cliente: this.datosPrincipales.name,
        slogancliente: this.datosPrincipales.slogan,
        fechafin: this.datosPrincipales.fecha_fin,
        tiempoTransito: this.datosPrincipales.tiempo_transito,
        origen:
          this.portBegin.filter(
            (v) => v.value == this.datosPrincipales.idPortBegin
          ).length > 0
            ? this.portBegin.filter(
                (v) => v.value == this.datosPrincipales.idPortBegin
              )[0].text
            : "",
        destino:
          this.portEnd.filter((v) => v.value == this.datosPrincipales.idPortEnd)
            .length > 0
            ? this.portEnd.filter(
                (v) => v.value == this.datosPrincipales.idPortEnd
              )[0].text
            : "",
        impuesto:
          this.services.filter((v) => v.codegroupservices == 15).length > 0
            ? this.services.filter((v) => v.codegroupservices == 15)[0]
                .status == true ||
              this.services.filter((v) => v.codegroupservices == 15)[0]
                .status == 1
              ? imp
              : 0
            : 0,

        flete: flete,
        almacen: almacen,
        aduana: aduana,
        local: locales,
        totalImpuesto: this.currencyFormat((totalImpuesto * 100) / 100),
        incluye: incluye,
        noincluye: noincluye,
        importante: importante,
        contenedor: contenedor,
        conceptos: conceptos,
        sentido: this.modality.filter(
          (v) => v.value == this.datosPrincipales.idmodality
        )[0].text,
        embarque:
          this.shipment.filter(
            (v) => v.value == this.datosPrincipales.idshipment.value
          ).length > 0
            ? this.shipment.filter(
                (v) => v.value == this.datosPrincipales.idshipment.value
              )[0].text
            : this.shipment.filter(
                (v) => v.value == this.datosPrincipales.idshipment
              )[0].text,
        icoterm: this.incoterm.filter(
          (v) => v.value == this.datosPrincipales.idincoterm
        )[0].text,
        //
        datosFlete: datosFlete,
        datosLocales: datosLocales,
        datosAduanas: datosAduanas,
        datosAlmacenes: datosAlmacenes,
        totalImpuestosIGV:
          this.id_branch == 1
            ? this.currencyFormat(
                (parseFloat(totalLocales) +
                  parseFloat(totalAduanas) +
                  parseFloat(totalAlmacenes)) *
                  0.18
              )
            : this.currencyFormat(0),
        //
        totalFlete: this.currencyFormat(totalFlete),
        totalLocales: this.currencyFormat(totalLocales),
        totalAduanas: this.currencyFormat(totalAduanas),
        totalAlmacenes: this.currencyFormat(totalAlmacenes),
        totalServicios: this.currencyFormat(totalServiciosPic),
        total: this.currencyFormat(
          (totalLocales + JSON.parse(localStorage.getItem("branch")) == 1
            ? totalAduanas
            : 0 + totalAlmacenes) *
            0.18 +
            totalServiciosPic +
            parseFloat(
              this.services.filter((v) => v.codegroupservices == 15).length > 0
                ? this.services.filter((v) => v.codegroupservices == 15)[0]
                    .status == true ||
                  this.services.filter((v) => v.codegroupservices == 15)[0]
                    .status == 1
                  ? totalImpuesto
                  : 0
                : 0
            )
        ),
      };
      let headers = {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "aplication/json",
        "Access-Control-Allow-Methods":
          "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers":
          "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length",
      };
      let url = "";
      if (this.tiporeporte === "AGRUPADO") {
        url = process.env.VUE_APP_URL_REPORT + "quote_preview_agrupado_upload";
      } else {
        url = process.env.VUE_APP_URL_REPORT + "quote_preview_totales_upload";
      }
      // let url = "https://api-reportes.piccargo.com/api/quote_preview";

      let response = await axios.post(url, data, headers);
      this.guardarRutaPreview(response.data);
    },
    cerrarModalPrev(e) {
      if (e) {
        Swal.fire(
          "ERROR",
          "Ocurrió un problema al generar el pdf,guarde la cotización y comuníquese con el administrador del sistema.",
          "error"
        );
      }
      this.previewFlag = false;
      this.generandoFlag = false;
    },
    async guardarRutaPreview(res) {
      let datos = {
        id_quote: this.$route.params.id,
        name: `Cotización N° : ${this.datosPrincipales.quote}`,
        type: "pdf",
        path: res.path,
        status: 1,
      };

      let config = {
        method: "post",
        url: process.env.VUE_APP_URL_MAIN + "setPath",
        headers: {
          "auth-token": JSON.parse(localStorage.getItem("token")),
          "Content-Type": "application/json",
        },
        data: datos,
      };
      await axios(config).then((response) => {
        if (response.data.statusBol == true) {
          this.value = 100;
          this.getFilesQuote(this.$route.params.id);
          this.setActualizarListaArchivos();
          setTimeout(() => {
            this.dialog = false;
          }, 2000);
        }
      });
    },
    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    },
    aumentarCarga(valor) {
      this.value = valor;
      if (valor < 99) {
        setTimeout(() => {
          this.aumentarCarga(valor + 1);
        }, 5);
      }
    },
  },

  computed: {
    ...mapState([
      "datosPrincipales",
      "registroCompleto",
      "portBegin",
      "portEnd",
      "services",
      "costos",
      "multiplicador",
      "modality",
      "shipment",
      "incoterm",
      "factor",
      "fleteTotal",
      "totalDeFlete",
      "totalOpcion",
      "totalVenta",
      "services",
      "totalImpuesto",
    ]),
  },
};
</script>

<style>
.v-expansion-panels.condensed .v-expansion-panel-header {
  padding-top: 2px;
  padding-bottom: 2px;
  min-height: auto;
}
.indexTblClass {
  width: 50px !important;
}
.btnTblClass {
  width: 50px !important;
  text-align: center !important;
}
.btnDescriptionClass {
  width: 100% !important;
  text-align: center !important;
}
</style>
